<template>
  <section class="jackpot-game">
    <div class="jackpot-game__list container-custom">
      <div class="jackpot-game__list__left">
        <div class="title">Tổng hũ sắp nổ</div>
        <div class="number">
          <CommonBaseImg src="/assets/images/icon/triangle-left.svg" class="icon-coin" alt="icon-coin" />
          <div v-if="sumJackpot['jackpotNohu'] > 0" class="jackpot">
            <AnimateNumber :number="sumJackpot['jackpotNohu']" :show-coin="false" />
            VND
          </div>
        </div>
        <nuxt-link to="/game/no-hu" class="button-see-all">Xem tất cả</nuxt-link>
      </div>
      <div class="jackpot-game__list__right">
        <Swiper v-if="listGameJackpotHome.length > 0" ref="mySwiper" v-bind="swiperOption" class="swiper-jackpot">
          <SwiperSlide v-for="(game, index) in listGameJackpotHome" :key="index" class="jackpot-game__list--item">
            <div class="">
              <CommonGamesItemGame :item="game" class-name="game-jackpot" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper'
import { useAppStore } from '@/store/app'
import { PAGE } from '~/constants/router'
import { useJackpot } from '~/composables/game/useJackpot'
import AnimateNumber from '~/components/common/animate-number.vue'
import { useGame } from '~~/composables/game/useGame'
import BaseButton from '~/components/common/base-button'
import CommonGamesItemGame from '~/components/common/game-item.vue'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot, jackpot } = storeToRefs(store)
const { fetchSumJackpot, fetchJackpot } = useJackpot()
const router = useRouter()
const mySwiper = ref<any>(null)
const swiperOption = reactive({
  slidesPerView: 5,
  slidesPerGroup: 1,
  spaceBetween: 12,
  observer: true,
  loop: true,
  autoplay: {
    delay: 3500,
    disableOnInteraction: false
  },
  centeredSlides: false,
  modules: [Autoplay, Navigation]
})
const { fetchListGameJackpot, listGameJackpotHome } = useGame()
useAsyncData(async () => {
  await fetchListGameJackpot()
  await fetchSumJackpot()
  await fetchJackpot()
})
</script>
<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/section-jackpot/index.scss"></style>
